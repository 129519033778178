<template>
  <div id="my">
    <mt-header fixed title="婚检信息建档"></mt-header>
    <div class="content">
    </div>
  </div>
</template>
<script>
import {
 /* nation, profession, education*/
  /*,maritalStatus,, zjlx*/
} from "@/common/Constants"

/*import {Toast} from "vant";*/
/*import {Indicator} from "mint-ui";*/
// import DialogUtil from "@/common/DialogUtil";

export default {
  name: "cjinfocreate",
  data() {
    return {
      nfxx: {//基本信息
        ZJLXDM_W: ''/*证件类型代码*/, ZJLX_W: '居民身份证'/*证件类型*/,
        SFZH: ''/*身份证号*/, XM: ''/*姓名*/,
        CSRQ: ''/*出生日期*/, SJH: ''/*手机号*/, MZ: ''/*民族*/, ZY: ''/*职业*/
        , XL: ''/*学历*/, GZDW: ''/*工作单位*/, HKXZ: '1'
        , XZZDM: ''/*现住址代码*/, XZZ: ''/*现住址*/, XZZXX: ''/*现住址详情*/ //现住址
        , HJDDM: ''/*户籍地代码*/, HJDZ: ''/*户籍地址*/, HJDZXX: ''/*户籍地址信息*///户籍地址、户籍地址详情
        , XYDDM: ''/**/, CHXYDZ: '', XYDXX: ''//修养地
      },
      nvxx: {//丈夫信息
        ZFZJLXDM: '', ZFZJLX: '', ZFSFZH: ''//丈夫证件类型、丈夫身份证号
        , ZFXM: '', ZFNL: '', ZFSJH: ''//丈夫姓名、丈夫年龄、丈夫手机号、
        , ZFHJDDM: '', ZFHJDZ: '', ZFHJDZXX: '' //丈夫户籍地址、丈夫户籍地址详情
        , ZFMZ: '', ZFXL: '', ZFZY: '', ZFGZDW: '' //丈夫民族、丈夫学历、丈夫职业、丈夫工作单位
      },
      jkxx: {//健康信息
        MCYJ: ''/*末次月经*/, YCQ: ''/*预产期*/, YJZQ: ''/*月经周期*/, YJJQ: ''/*月经经期*/
        , QZFS: ''/*确诊方法*/, YCS_G: ''/*怀孕次数*/, YCS_P: ''/*孕产史-分娩次数*/
        , YCS_A: ''/*孕产史-流产次数*/
        , YQXY_SSY: ''/*孕前血压(收缩压)*/, YQXY_SZY: ''/*孕前血压（舒张压）*/
        , YQTZ: '' /*孕期体重KG*/, SG: ''/*身高*/
        , DQXY_SSY: ''/*当前血压(收缩压)*/, DQXY_SZY: ''/*当前血压(舒张压)*/
        , DQTZ: ''/*当前体重*/
      },
      jbsxx: {//疾病史信息
        JZS: ''/*家族史*/, JWS: ''/*既往史*/, GRS: ''/*个人史*/
        , YCQK: ''/*异常情况*/, FKSS: ''/*妇科手术*/
      },
    }
  }
}

</script>
<style>


.mint-header {
  font-size: 18px;
  background-color: #eb89a9;
}
</style>